import classNames from 'classnames';
import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends ComponentProps<'button'> {
	variant?: 'primary' | 'secondary';
	small?: boolean;
}

export const Button = ({ small, variant = 'primary', ...props }: Props) => {
	const className = twMerge(
		`transition-all font-semibold py-2 px-4 rounded ${props.className} disabled:dark:bg-blue-900 disabled:hover:bg-asdf disabled:hover:cursor-not-allowed`,
		variant === 'primary' && 'bg-blue-500 hover:bg-blue-700 text-white',
		variant === 'secondary' && 'bg-gray-500 hover:bg-gray-600 text-white',
		small && 'text-sm'
	);

	return (
		<button {...props} className={className}>
			{props.children}
		</button>
	);
};
