import { Session } from 'next-auth';
import { SessionProvider, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import type { AppPropsWithLayout } from '~/pages/_app';
import { routes } from './routes';
import { Inspector } from '~/components/Inspector';

function AppWrapper(props: AppPropsWithLayout) {
	const { Component, pageProps } = props;

	// Use the layout defined at the page level, if available
	const getLayout = Component.getLayout ?? ((page) => page);

	return (
		<>
			<SessionProvider session={pageProps.session}>
				{getLayout(<Component {...pageProps} />)}
				<Inspector />
			</SessionProvider>
		</>
	);
}

export default AppWrapper;

/**
 *
 */
export const useRequiredSession = (): [Session, true] | [null, false] => {
	const router = useRouter();
	// This will throw unless we've wrapped the component calling this inside a
	// <SessionProvider />.
	const { data, status } = useSession();

	// README, I think this redirection logic can be handled directly by passing
	// args to useSession().

	if (status === 'loading') {
		return [null, false];
	} else if (status === 'unauthenticated') {
		router.push(routes.index);
		return [null, false];
	} else if (status === 'authenticated') {
		return [data as any, true];
	}
	throw new Error(`Unexpected status: ${status}`);
};
