import { useSession } from 'next-auth/react';
import { useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { Button } from '~/shared/ds';
import { ModalContent, ModalWrapper } from './modal-utils';

export function Inspector() {
	const [isVisible, setIsVisible] = useState(false);

	return (
		<>
			<div className="absolute bottom-3 right-3">
				<Button onClick={() => setIsVisible(true)}>Debug</Button>
			</div>
			{isVisible && <InspectionModal hide={() => setIsVisible(false)} />}
		</>
	);
}

function InspectionModal({ hide }: { hide: () => void }) {
	const session = useSession();
	const { user } = useUserState();

	return (
		<ModalWrapper hide={hide}>
			<ModalContent>
				<div className="p-4 flex flex-col gap-3">
					<p>Inspection Modal</p>
					<section>
						<p className="text-md">Next-auth session:</p>
						<pre className="text-sm">{JSON.stringify(session, null, 2)}</pre>
					</section>
					<section>
						<p className="text-md">Local storage `user_state`:</p>
						<pre className="text-sm">{JSON.stringify(user, null, 2)}</pre>
					</section>
				</div>
			</ModalContent>
		</ModalWrapper>
	);
}

export type LocalUserState = {
	connections: Record<string, { externalId: string }>;
} | null;

const LOCAL_STORAGE_KEY = 'user_state';
/**
 * A hacked-together hook to save and retrieve the user state. In an actual
 * application, this would be backed by a database.
 */
export function useUserState() {
	const [value, setValue] = useLocalStorage<LocalUserState>(
		LOCAL_STORAGE_KEY,
		null
	);

	const user: LocalUserState = useMemo(() => value ?? null, [value]);

	return {
		user,
		saveUser: (newValue: LocalUserState) => {
			setValue(newValue);
		},
	};
}

export function getUserState(): LocalUserState | null {
	const value = localStorage.getItem(LOCAL_STORAGE_KEY);
	return value ? JSON.parse(value) : null;
}
