export const routes = {
	index: '/',
	ecommerce: {
		auth: {
			signin: '/ecommerce/signin',
		},
		index: '/ecommerce',
		orders: '/ecommerce/orders',
		customers: '/ecommerce/customers',
		integrations: '/ecommerce/integrations',
		integrationCallback: '/ecommerce/integrations/callback',
		integrationDisconnect: '/ecommerce/integrations/disconnect',
	},
};
