import { twMerge } from 'tailwind-merge';

interface ModalWrapperProps {
	children: React.ReactNode;
	hide: () => void;
}

export function ModalWrapper({ children, hide }: ModalWrapperProps) {
	function onClickBackdrop(e: React.MouseEvent<HTMLDivElement>) {
		// Only close the modal if the user clicks on the backdrop.
		if (e.target === e.currentTarget) {
			hide();
		}
	}

	return (
		<div
			className="fixed inset-0 flex items-center bg-black/30 justify-center cursor-pointer"
			onClick={onClickBackdrop}
		>
			{children}
		</div>
	);
}

export function ModalContent({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<div
			className={twMerge(
				'w-[500px] min-h-[400px] overflow-hidden bg-white dark:bg-black rounded-lg shadow-lg cursor-default',
				className
			)}
		>
			{children}
		</div>
	);
}
