import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import { Toaster } from 'react-hot-toast';
import AppWrapper from '~/shared/AppWrapper';
import '../styles/globals.css';

export type NextPageWithLayout<T = any> = NextPage<T> & {
	getLayout?: (page: ReactElement) => ReactElement;
};

export type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
	// Apparently we can't import this Session frm next-auth/react
	session: any;
};

function _App(props: AppPropsWithLayout) {
	const { Component, pageProps } = props;
	const router = useRouter();

	let content;
	if (router.pathname.match(/^\/(ecommerce|ai)/)) {
		content = <AppWrapper {...props} />;
	} else {
		content = <Component {...pageProps} />;
	}

	return (
		<>
			<Head>
				<link
					rel="icon"
					href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🤑</text></svg>"
				/>
			</Head>
			<Toaster position="bottom-center" />
			<Head>
				<title>Shoplytics – Ecommerce analytics</title>
				<link rel="shortcut icon" href="/favicon.png" />
			</Head>
			{content}
		</>
	);
}

export default _App;
